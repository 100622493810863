<template>
    <!-- <iframe :src="baseData" :type="type" allowfullscreen style="width:100vw;height:100vh;"></iframe> -->
    <iframe :src="baseData" :type="type" allowfullscreen style="width: 100vw; height: 100vh;" loading="lazy" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"></iframe>

</template>
<script>
import axios from 'axios';
export default {
    data(){
        return {
            baseData:'',
            type:''
        }
    },
    mounted() {
        let vm = this;
        
        if(this.$route.params.document_type=="document_out"){
            axios.post('document_out/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        } else if (this.$route.params.document_type=="draftingApprove") {
            axios.post('document_out/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        } else if(this.$route.params.document_type=="disposition_in" || this.$route.params.document_type=="disposition_send" || this.$route.params.document_type=="disposition_already_send"){
            axios.post('disposition_in/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        }else if(this.$route.params.document_type=="drafting"){
            axios.post('drafting/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            }); 
        }else if(this.$route.params.document_type=="surat_diberkaskan"){
            axios.post('surat_diberkaskan/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        }else if(this.$route.params.document_type=="pemberkasan"){
            axios.post('pemberkasan/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        }else if(this.$route.params.document_type=="agenda"){
            axios.post('agenda/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                console.log(err);
            });
        }else if(this.$route.params.document_type=="meeting"){
            axios.post('meeting/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                console.log(err);
            });
            
        }else if(this.$route.params.document_type=="disposition_in_surat"){
            console.log(this.$route.params.document_type);
            console.log("Testing");
            console.log(this.$route.params.file);
            axios.post('document_in/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                console.log(vm.baseData);
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
                console.log("Filenya");
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        }else if(this.$route.params.document_type=="document_in"){
            console.log(this.$route.params.document_type);
            console.log("Testing");
            console.log(this.$route.params.file);
            axios.post('document_in/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                console.log(vm.baseData);
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
                console.log("Filenya");
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        }else{
            axios.post('document_in/assets/single',{filepath:this.$route.params.file}).then(res=>{
                vm.baseData = res.data;
                let type = vm.baseData.split(';')[0];
                vm.type = type.replace('data:','');
            }).catch(err=>{
                // eslint-disable-next-line
                console.log(err);
            });
        }
    },    
}
</script>
